export interface PrinterStateType {
  availablePrinters: PrinterResponseType[]
  printerConfigs: PrinterType[]
  modals: {
    default_printer: boolean
  }
  status: 'connected' | 'not installed' | 'blocked' | 'waiting' | 'disconnected'
  printing: boolean
  loading: boolean
  checking: boolean
  printer?: PrinterType
}

export interface PrinterType {
  printer_config:
    | 'delivery_notes'
    | 'carrier_labels'
    | 'component_labels'
    | 'collection_labels'
    | 'receipts'
    | 'tax_invoices'
    | 'quotes'
  printer_name: string
  file_name: string
  file_type: 2 | 0 | 3 | 1
  printer: PrinterResponseType | null
  paper_media?: string
  paper_type?: string | null
  paper_size?: string
  paper_sizes?: string[]
  contents: string | Blob
  copies: number
  paper_fit: boolean
  auto_print?: boolean
  paper_autocenter: boolean
  paper_autorotate: boolean
  paper_grayscale: boolean
}

export interface sendToPrinterType {
  printer_name: PrinterType['printer_name']
  contents: PrinterType['contents']
  file_type: PrinterType['file_type']
  file_name: string
  printer: PrinterResponseType | null
  paper_type: PrinterType['paper_type']
  paper_size: PrinterType['paper_size']
  copies: PrinterType['copies']
  paper_fit: PrinterType['paper_fit']
  paper_autocenter: PrinterType['paper_autocenter']
  paper_autorotate: PrinterType['paper_autorotate']
  paper_grayscale: PrinterType['paper_grayscale']
}

export interface PrinterResponseType {
  BIDIEnabled: boolean
  XDPI: number
  YDPI: number
  connected: boolean
  default: boolean
  duplex: boolean
  icon: string
  isLocal: boolean
  isNetwork: boolean
  isShared: boolean
  name: string
  papers: string[]
  port: string
}

export enum PrinterConfigFileNames {
  deliveryNotes = 'delivery_notes',
  carrierLabels = 'carrier_labels',
  componentLabels = 'component_labels',
  collectionLabels = 'collection_labels',
  quotes = 'quotes',
  receipts = 'receipts',
  taxInvoices = 'tax_invoices'
}

export type channelDeliveryNotesPayloadType = {
  data: {
    region: string
    campfire_user_id: string
    order_id: string
    ordernum: string
    syspro_order_id: string
    syspro_invoice_id: string
  }
}

export type channelCarrierLabelsPayloadType = {
  shipmentId: number
  labelId: number
  region: string
  orderId: string
  carrier: string
  carrierService: string
  campfireUserId: number
  sysproOrderId: string
  sysproInvoiceId: string
}
